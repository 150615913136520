/* @font-face {
    font-family: Europa-Regular;
    src: url("./assets/font/Europa-Regular.ttf");
} */
@import url(https://db.onlinewebfonts.com/c/d3bfee78e8ead45d9057a95bb6ff5de8?family=Europa-Bold);
@import url(https://db.onlinewebfonts.com/c/23c0fcab84d99da0de762de7e220a6e1?family=Europa);

@font-face {
    font-family: "Europa-Bold";
    src: url("https://db.onlinewebfonts.com/t/d3bfee78e8ead45d9057a95bb6ff5de8.eot");
    src: url("https://db.onlinewebfonts.com/t/d3bfee78e8ead45d9057a95bb6ff5de8.eot?#iefix") format("embedded-opentype"), url("https://db.onlinewebfonts.com/t/d3bfee78e8ead45d9057a95bb6ff5de8.woff2") format("woff2"), url("https://db.onlinewebfonts.com/t/d3bfee78e8ead45d9057a95bb6ff5de8.woff") format("woff"), url("https://db.onlinewebfonts.com/t/d3bfee78e8ead45d9057a95bb6ff5de8.ttf") format("truetype"), url("https://db.onlinewebfonts.com/t/d3bfee78e8ead45d9057a95bb6ff5de8.svg#Europa-Bold") format("svg");
}

@font-face {
    font-family: "Europa";
    src: url("https://db.onlinewebfonts.com/t/23c0fcab84d99da0de762de7e220a6e1.eot");
    src: url("https://db.onlinewebfonts.com/t/23c0fcab84d99da0de762de7e220a6e1.eot?#iefix") format("embedded-opentype"), url("https://db.onlinewebfonts.com/t/23c0fcab84d99da0de762de7e220a6e1.woff2") format("woff2"), url("https://db.onlinewebfonts.com/t/23c0fcab84d99da0de762de7e220a6e1.woff") format("woff"), url("https://db.onlinewebfonts.com/t/23c0fcab84d99da0de762de7e220a6e1.ttf") format("truetype"), url("https://db.onlinewebfonts.com/t/23c0fcab84d99da0de762de7e220a6e1.svg#Europa") format("svg");
}


body {
    font-family: Europa !important;
}

.alert-success {
    color: white;
    background-color: #1d8102;
    border-color: #1d8102;
    border-radius: 0px;
}

.alert.collapsed {
    margin-right: 260px;
}

.notification-close {
    color: white;
    opacity: 1;
    font-size: unset;
    float: right;
}

.alert-danger {
    color: #a94442;
    background-color: #F5C5BB;
    border-color: #F5C5BB;
    border-radius: 0px;
}

.alert-danger .notification-close {
    color: #a94442;
}

div.container-fluid {
    height: 100%;
}

header.sc-hHLeRK {
    justify-content: center !important;
}

.list-btn-add {
    position: absolute;
    top: 11px;
    z-index: 1;
    right: 11px;
}

.list-btn-add span {
    background: #fff;
    color: #dc3545;
    margin: 5px;
    padding: 0 2px;
    border: none !important;
}

.table-action .dropdown-toggle::after {
    content: '';
    border: none;
    transform: none;
    padding: 0px;
    z-index: 2;
}
  
.table-action .btn-light {
    background: white;
    border: white;
}

.table-action .dropdown-menu {
    text-align: left !important;
    margin-top: 0 !important;
    min-width: auto;
}

.table-action .dropdown-menu a{
    color: #434657 !important;
}

.table-action .dropdown-item:hover {
    background: rgba(0, 0, 0, 0.1) !important;
    color: #434657 !important;
}

/* title section */
.title-section {
    margin-top: 26px;
    margin-bottom: 26px;
    margin: 26px 13px;
  }
  
.title-section .title-header {
    font-size: 26px;
    font-weight: bold;
    width: max-content;
}

.title-section .search-bar {
    min-width: max-content;
    border-bottom: 1px solid rgb(179, 179, 179);
    padding: 0;
    height: 35px;
    margin: 0 20px;
}

.title-section .search-bar input{
    height: 30px;
    width: 110%;
    margin: 0;
    padding: 10px 0 0 0;
}

.title-section .select-material {
    min-width: 100px;
}

.title-section .add-btn {
    min-width: max-content;
    float: right;
    text-align: right;
}

.test-group-search {
    border: none;
}

.search-bar .fa-magnifying-glass {
    color: #B3B3B3;
    float: right;
    margin-top: 13px;
}

.form-select {
    padding: 0.3rem 2.35rem 0.3rem 0.85rem !important
}

.title-section .form-control:focus {
    border-color: rgb(179, 179, 179) !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.search-label {
    width: 80%;
}

/* .react-bootstrap-table {
margin: 0 20px;
} */

.react-bootstrap-table .row-expand-slide-exit-active {
    transition: none !important;
}

.react-bootstrap-table .row-expand-slide-appear-active {
    transition: none !important;
}

.react-bootstrap-table-pagination-list-hidden {
    display: none;
}

.react-bootstrap-table-pagination-list {
    margin-left: 43%;
}

.table thead th {
    border-top: none;
}

span.rounded-circle {
    background: white;
    color: #dc3545;
    margin: 5px;
    padding: 0 2px;
    border: none !important;
}

.users-search {
    border: none;
}

.header-class th {
    vertical-align: middle !important;
}

.react-bootstrap-table {
    overflow-x: auto;
    min-height: 651.33px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
    input#search, header .search-not-add {
        width: auto !important;
    }
}